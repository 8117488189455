import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Slide } from "@material-ui/core";
import { date } from "yup";

const BlogContent = [
  {
    img: "blog29-2.jpg",
    date: "Oct 07, 2024",
    title: `Why Co-working Spaces Are Perfect for Freelancers`,
    desc: `In today's evolving work environment, more and more freelancers are choosing co-working spaces over traditional home offices or coffee shops. The appeal is clear: co-working spaces offer the ideal blend`,
    routePath: "/why-coworking-spaces-are-perfect-for-freelancers",
  },
  {
    img: "blog281.jpg",
    date: "Sep 30, 2024",
    title: `Balancing Privacy and Collaboration in Professional Workspaces`,
    desc: `In today's dynamic professional environment, companies are increasingly embracing open and collaborative workspaces to foster innovation, communication, and teamwork.`,
    routePath: "/balancing-privacy-and-collaboration-in-professional-workspaces",
  },
  {
    img: "blog272.jpg",
    date: "Sep 23, 2024",
    title: `Designing a Professional Workspace for Remote Teams`,
    desc: `With the rise of remote work, creating a professional and productive workspace has become more important than ever. A well-designed workspace can enhance productivity`,
    routePath: "/designing-a-professional-workspace-for-remote-teams",
  },

];

const BlogStyle3 = () => {
  const settings = {
    dots: false,
    arrow: true,
    infinite: true,
    speed: 900,
    centerMode: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    centerPadding: "0",
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <>
      {BlogContent.map((item, i) => (
        <div className="post-meta" key={i}>
          <div className="imgsetup">
            <img
              src={`images/assets/${item.img}`}
              alt="media"
              className="image-meta1"
            />
          </div>
          <div className="post">
            <div className="date">{item.date}</div>
            <h3>
              <Link to={item.routePath} className="title">
                {item.title}
              </Link>
            </h3>
            <p>{item.desc}</p>
            <Link
              to={item.routePath}
              className="read-more d-flex justify-content-between align-items-center"
            >
              <span>Learn More</span>
              {/* <i className="flaticon-right-arrow"></i> */}
            </Link>
          </div>
          {/* End post */}
        </div>
        // /.post-meta
      ))}
    </>
  );
};

export default BlogStyle3;
